<template>
  <div class="container">
    <h1>Feedback</h1>
    <p>Please send your queries to <a href="mailto:support@queira.com">support@queira.com</a></p>
    <br />
  </div>
</template>
<script>
export default {
  name: 'Feedback',
  created(){
    document.title = "Visicardia :: Feedback";
  },
  beforeDetroy() {
    document.title = "Visicardia";
  },
}
</script>